/* global gtag */
import React, { useState, useEffect } from 'react';
import Header from './Header';
import ListPage from './ListPage';
import EditDrinkPage from './EditDrinkPage';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

export const routes = {
  home: 'home',
  edit: 'edit',
}

function IdGenerator() {
  let i = 1;

  return {
    generate: () => i++
  }
}

const id = new IdGenerator();

function App() {
  const [route, setRoute] = useState(null);
  const [drinks, setDrinks] = useState([]);
  const [editing, setEditing] = useState(null);

  useEffect(() => {
    setRoute(routes.home);
  }, []);

  function onSubmit(drink) {
    if (drink.id) { 
      const existingIndex = drinks.findIndex(d => d.id === drink.id);
      if (existingIndex < 0) {
        return;
      }

      const newDrinks = [].concat(drinks);
      newDrinks[existingIndex] = drink;
      gtag('event', 'save_existing');
      setDrinks(newDrinks);
    } else {
      drink.id = id.generate();
      gtag('event', 'save_new');
      setDrinks([
        drink,
        ...drinks
      ]);
    }

    setRoute(routes.home);
  }

  const viewForRoute = route => {
    if (route === routes.home) {
      return <ListPage 
        drinks={drinks} 
        addNewDrink={() => {
          setRoute(routes.edit)
          gtag('event', 'create_drink');
        }} 
        editDrink={(id) => { 
          setEditing(drinks.find(d => d.id === id));
          gtag('event', 'edit_drink');
          setRoute(routes.edit);
        }} 
      />;
    }
    else if (route === routes.edit) {
      return <EditDrinkPage editing={editing} 
        onSubmit={onSubmit} 
        onCancel={() => {
          if (editing) {
            gtag('event', 'cancel_edit');
          } else {
            gtag('event', 'cancel_new');
          }
          setRoute(routes.home);
        }} 
      />;
    }
    return <div></div>;
  };

  return (
    <div className="h-full w-full flex flex-col">
      <Header />
      <div className="flex-grow overflow-x-scroll h-full w-full">
        <SwitchTransition>
          <CSSTransition 
            key={route}
            in={true}
            timeout={300} 
            classNames="fade"
          >
            {viewForRoute(route)}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
}

export default App;
