import React from 'react';

const round = (fig, dp = 2) => Number(fig.toFixed(dp));

function standardDrinks(volume, percent) {
  return volume / 1000 * percent * 0.789;
}

function standardDrinksPerDollar(drink) {
  const totalStandards = standardDrinks(drink.volume * drink.amount, drink.percent);
  return round((totalStandards / drink.cost) || 0);
}

export default function ListPage({ className, drinks, addNewDrink, editDrink }) {
  return (<div className="w-full h-full">
    <button onClick={addNewDrink}
      className="bg-theme-light rounded-full absolute shadow-lg bottom-0 right-0 w-16 h-16 text-2xl text-white shadow-md mr-8 mb-8">
      +
    </button>
    {drinks.length === 0 && <div className="w-full h-full flex flex-col justify-around text-center px-8 text-2xl text-gray-500">
      <div>Get started by adding<br />a drink to compare</div>
    </div>}
    <ul>
      {drinks.sort((a, b) => {
        return standardDrinksPerDollar(a) <= standardDrinksPerDollar(b) ? 1 : -1;
      }).map((drink, index) => (<li key={index} className="p-2 border-b flex">
        <div className="px-2 mr-4 flex flex-col justify-center text-center">
          <div>
            <span className="font-bold text-4xl leading-none text-gray-700">{standardDrinksPerDollar(drink)}</span>
          </div>
          <div className="leading-none"><span className="text-xs ml-1">standards / dollar</span></div>
        </div>
        <div className="flex-grow" style={{lineHeight: '1.3'}}>
          <div className="py-1">
            <span className="text-xl font-semibold">{drink.name}</span>
          </div>
          <div className="text-gray-800 mt-1">
            <div>
              <span className="text-2xl">{drink.amount}</span><span className="text-sm mx-2">drinks at</span><span className="text-2xl">{drink.volume}</span>mL
            </div>
            <div>
              <span className="text-2xl">{drink.percent}</span>%   <span className="text-2xl">${drink.cost}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center mr-2">
          <button onClick={() => editDrink(drink.id)} className="text-sm text-gray-600 underline">edit</button>
        </div>
      </li>))}
    </ul>
  </div>)
}