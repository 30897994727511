import React, { useState } from 'react';

const defaultDrink = {
  name: '',
  volume: 0,
  amount: 0,
  cost: 0,
  percent: 0
};

export default function EditDrinkPage({ editing, onSubmit, onCancel }) {
  const [drink, setDrink] = useState(editing || defaultDrink);

  const s = (key) => {
    return (event) => {
      if (!Object.keys(drink).find(d => d === key)) {
        return;
      }
  
      setDrink({
        ...drink,
        [key]: event.target.value
      })
    }
  }

  return (<div className="p-4">
    <h1 className="text-4xl text-gray-700 mb-6 mt-2">Add drink details</h1>
    <form onSubmit={(event) => { event.preventDefault(); onSubmit(drink) }} >
      <fieldset className="mb-4">
        <label htmlFor="name" className="block mb-1">Name</label>
        <input id="name" className="border w-full p-2 rounded" type="text" value={drink.name} placeholder="Tasty drink" required onChange={s('name')} />
      </fieldset>
      <fieldset className="mb-2">
        <label htmlFor="name" className="block mb-1">Percent</label>
        <input id="name" className="border w-full p-2 rounded" type="number" required value={drink.percent} onChange={s('percent')} />
      </fieldset>
      <fieldset className="mb-2">
        <label htmlFor="name" className="block mb-1">Number of Drinks <span className="text-sm">(e.g. 1 bottle or 6 cans)</span></label>
        <input id="name" className="border w-full p-2 rounded" type="number" required value={drink.amount} onChange={s('amount')} />
      </fieldset>
      <fieldset className="mb-2">
        <label htmlFor="name" className="block mb-1">Volume <span className="text-sm">(mL)</span></label>
        <input id="name" className="border w-full p-2 rounded" type="number" required value={drink.volume} onChange={s('volume')} />
      </fieldset>
      <fieldset className="mb-2">
        <label htmlFor="name" className="block mb-1">Total Cost <span className="text-sm">($)</span></label>
        <input id="name" className="border w-full p-2 rounded" type="number" required step="0.01" min="0" value={drink.cost} onChange={s('cost')} />
      </fieldset>

      <div className="w-full flex my-8">
        <input className="p-4 rounded bg-theme-light text-white border-theme-mid border mr-2 flex-grow" type="submit" value="Done" />
        <button type="button" className="p-4 rounded border border-gray-300 flex-grow bg-gray-100" onClick={onCancel}>Cancel</button>
      </div>
    </form>
  </div>)
}